import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import { FaDiscord, FaFacebookF } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";

import { toast } from "react-toastify";
import axios from "axios";
import apiConfig, { websiteName } from "src/config/ApiConfig";
import {} from "react-feather";
import Logo from "./../../component/Logo";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#f2f2f2",
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: "32px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  input_fild: {
    backgroundColor: "#e1e1e1",
    borderRadius: "50px",
    color: "#000",
    height: "55px",
    width: "100%",
    borderBottom: "none",
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-input": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: 0,
    },
  },

  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    borderRadius: "10px",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  socialIcon: {
    display: " flex",
    alignItems: "center",
    marginTop: "10px",
    "& svg": {
      color: "#aeadad",
      cursor: "pointer",
      marginRight: " 10px",

      "&:hover": {
        color: "#39076a",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const UserSubscribe = async () => {
    try {
      const res = await axios.post(apiConfig.userSubscribe, {
        email: email,
      });

      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          // setNoOfPages(res.data.result.pages);
          // setActivityNFTList(res.data.result.docs);
          console.log(res.data.result);
        }
      }
      // setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      // setIsLoading(false);
    }
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Box style={{ margin: "20px 10px 0" }}>
          <Container maxWidth="lg">
            <Grid container justify="space-around" spacing={1}>
              <Grid item xs={12} md={3}>
                <img
                  src="images/footerlogo.png"
                  style={{ maxWidth: "160px" }}
                />
                <Box className={classes.socialIcon}>
                  {/* < />  */}
                  <a href="/" target="_blank">
                    <TwitterIcon />
                  </a>
                  <a href="/" target="_blank">
                    <TelegramIcon />
                  </a>
                  <a href="/" target="_blank">
                    <FaDiscord style={{width:"24px",height:"24px"}}/>{" "}
                  </a>
                  <a href="/" target="_blank">
                    <ImLinkedin2 style={{width:"24px",height:"24px",paddingBottom:".3rem"}}/>
                  </a>
                </Box>
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/">All rights reserved</Link>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography variant="h5">Content</Typography>
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/marketplace">Explore</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/help-center">Help center</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/">Blog</Link>
                    <ListItem>
                      <Link to="/">Suggest feature</Link>
                    </ListItem>
                  </ListItem>
                </List>
              </Grid>
              {/* <Grid item xs={6} md={3}>
                <Typography variant="h5">Community</Typography>
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/">Discussion</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/">Voting</Link>
                  </ListItem>
                </List>
              </Grid> */}

              <Grid item xs={12} md={12} lg={4}>
                <Typography variant="h5">Get the latest updates</Typography>

                <Box mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box className={classes.inputBox}>
                        <TextField
                          placeholder="Your e-mail"
                          variant="filled"
                          color="secondary"
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth
                          // className={classes.input_fild}
                        />
                        <Button
                          onClick={() => {
                            UserSubscribe();
                            setEmail("");
                          }}
                          className={classes.signupBtn}
                        >
                          I'm in
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
